import cx from 'classnames';

import Modal, { ModalProps } from '../Modal';

import styles from './Dialog.module.css';
import { PropsWithChildren } from 'react';
import { BoxStyleProps } from '../Box';

export interface DialogProps extends Omit<ModalProps, 'className'>, BoxStyleProps {
  className?: string;
}

export default function ({
  className,
  contentClassName,
  ...otherProps
}: PropsWithChildren<DialogProps>) {
  return (
    <Modal
      className={cx(styles['base'], className)}
      contentClassName={cx(styles['content'], contentClassName)}
      {...otherProps}
    />
  );
}
