import { useGetRefundProposalQuery } from '../../../graphql/generated';
import { useAppStore } from '../../../main/store';
import {
  checkGraphqlError,
  currency_disabled_errors,
} from '../../../services/apollo/utils';

export default function useRefundRequest() {
  const setRefundCurrencyDisabled = useAppStore(
    (state) => state.setRefundCurrencyDisabled
  );
  const { data, loading } = useGetRefundProposalQuery({
    onError: (error) => {
      if (error.graphQLErrors) {
        const isCurrencyDisabledError = checkGraphqlError(
          error.graphQLErrors,
          currency_disabled_errors
        );
        if (isCurrencyDisabledError) {
          setRefundCurrencyDisabled(true);
        }
      }
    },
  });

  const isUSDTPaymentRefund =
    data?.refund?.sourceCryptoAmount?.billPaymentCurrency === 'USDT';

  const cryptoToCrypto =
    data?.refund?.initialAmount?.currency ===
    (isUSDTPaymentRefund
      ? 'USDT'
      : data?.refund?.proposal?.convertedAmount?.currency);

  const networkData = data?.refund?.proposal?.convertedAmount?.blockchain;

  const network = networkData === 'LN-BTC' ? 'BTC' : networkData;

  return {
    loading,
    hasProposal: !!data?.refund?.proposal,
    data: {
      id: data?.refund?.id,
      cryptoAmount: data?.refund?.proposal?.convertedAmount?.value,
      isUSDTPaymentRefund,
      cryptoCurrency: data?.refund?.proposal?.convertedAmount?.currency,
      network,
      fiatAmount: cryptoToCrypto ? null : data?.refund?.initialAmount?.value,
      fiatCurrency: cryptoToCrypto
        ? null
        : data?.refund?.initialAmount?.currency,
      exchangeRate: data?.refund?.proposal?.exchangeRate,
      expiresAt: data?.refund?.proposal?.expiresAt,
    },
  };
}
