import cx from 'classnames';
import { ReactNode, useEffect } from 'react';
import { Variants, motion } from 'framer-motion';

import Icon from '../Icon';

import styles from './Toast.module.css';

import { ReactComponent as AlertCircleSvg } from '@frontend/icons/alert-circle-filled.svg';
import { ReactComponent as AlertTriangleSvg } from '@frontend/icons/alert-triangle-filled.svg';
import { ReactComponent as CheckCircleSvg } from '@frontend/icons/check-circle-2-filled.svg';
import { ReactComponent as InfoSvg } from '@frontend/icons/info-filled.svg';
import { ReactComponent as CrossSvg } from '@frontend/icons/cross.svg';

import Box, { BoxStyleProps } from '../Box';
import Text from '../Text/Text';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import useResponsive, { SCREEN_SIZE } from '../useResponsive/useResponsive';

export interface ToastProps extends BoxStyleProps {
  className?: string;
  status?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  message?: ReactNode;
  autoHideDuration?: number;
  onRequestClose?: () => void;
}

const iconComponent = {
  success: <CheckCircleSvg />,
  error: <AlertCircleSvg />,
  warning: <AlertTriangleSvg />,
  info: <InfoSvg />,
};

export const MIN_WIDTH = 200;

export default function Toast({
  className,
  status = 'success',
  title,
  message,
  autoHideDuration,
  onRequestClose,
  ...otherProps
}: ToastProps): JSX.Element {
  useEffect(() => {
    let autoHideTimer: ReturnType<typeof setTimeout>;

    if (autoHideDuration && onRequestClose) {
      autoHideTimer = setTimeout(() => {
        onRequestClose();
      }, autoHideDuration);
    }

    return () => {
      clearTimeout(autoHideTimer);
    };
  }, [autoHideDuration]);

  const bp = useResponsive();

  const isMobile =
    bp === SCREEN_SIZE.XS || bp === SCREEN_SIZE.SM || bp === SCREEN_SIZE.MD;

  const animationOffset = 80;

  const variants: Variants = {
    hidden: (isMobile) => ({
      ...(isMobile ? { y: -animationOffset } : { x: animationOffset }),
      opacity: 0,
    }),
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: 'easeOut' },
    },
    exit: (isMobile) => ({
      ...(isMobile ? { y: -animationOffset } : { x: animationOffset }),
      opacity: 0,
      transition: { duration: 0.3, ease: 'easeOut' },
    }),
  };

  return (
    <Box
      as={motion.div}
      variants={variants}
      custom={isMobile}
      initial="hidden"
      animate="visible"
      exit="exit"
      {...otherProps}
    >
      <div>
        <div
          className={cx(
            styles['base'],
            [styles[`base--${status}`]],
            { [styles['base--centered']]: !message },
            className
          )}
          role="alert"
          style={{ minWidth: MIN_WIDTH }}
        >
          <div className={styles['icon-wrapper']}>
            <Icon
              size={20}
              className={styles['statusIcon']}
              component={iconComponent[status]}
            />
          </div>
          <div
            className={styles['main']}
            style={{
              paddingTop: message ? '4px' : '0',
              paddingBottom: message ? '4px' : '0',
            }}
          >
            <Text weight="600" color="neutral-900">
              {title}
            </Text>
            {message &&
              (typeof message === 'string' ? (
                <Text mt="4" size="small" color="neutral-500">
                  {message}
                </Text>
              ) : (
                <Box mt="4">{message}</Box>
              ))}
          </div>
          <UnstyledButton
            ml="8"
            mt={message ? '4' : '0'}
            onClick={onRequestClose}
          >
            <Icon component={<CrossSvg />} size={24} color="neutral-500" />
          </UnstyledButton>
        </div>
      </div>
    </Box>
  );
}
