import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: any;
  Decimal: any;
  Json: any;
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Maybe<Scalars['Datetime']>;
  data: Maybe<Scalars['Json']>;
  eventType: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  orderId: Maybe<Scalars['String']>;
};

export type Amount = {
  __typename?: 'Amount';
  billPaymentCurrency: Maybe<Scalars['String']>;
  blockchain: Maybe<Scalars['String']>;
  currency: Maybe<Currency>;
  value: Maybe<Scalars['Decimal']>;
};

export type Claim = {
  __typename?: 'Claim';
  escalationReason: Maybe<Scalars['String']>;
  winner: Maybe<ClaimWinner>;
};

export enum ClaimWinner {
  Buyer = 'BUYER',
  Merchant = 'MERCHANT',
}

export enum Currency {
  Aed = 'AED',
  Ars = 'ARS',
  Aud = 'AUD',
  Brl = 'BRL',
  Btc = 'BTC',
  Cad = 'CAD',
  Celo = 'CELO',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Czk = 'CZK',
  Dash = 'DASH',
  Dgb = 'DGB',
  Dkk = 'DKK',
  Dop = 'DOP',
  Egld = 'EGLD',
  Etc = 'ETC',
  Eth = 'ETH',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pol = 'POL',
  Ride = 'RIDE',
  Ron = 'RON',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Sui = 'SUI',
  Thb = 'THB',
  Trx = 'TRX',
  Twd = 'TWD',
  Usd = 'USD',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Utk = 'UTK',
  Zar = 'ZAR',
}

/** Information the buyer entered when creating the order */
export type Customer = {
  __typename?: 'Customer';
  address1: Maybe<Scalars['String']>;
  address2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

/** A dispute that needs to be resolved between buyer and merchant */
export type Dispute = {
  __typename?: 'Dispute';
  claim: Maybe<Claim>;
  escalationReason: Maybe<Scalars['String']>;
  evidence: Maybe<Array<Maybe<Evidence>>>;
  id: Maybe<Scalars['String']>;
  messages: Maybe<Array<Maybe<Message>>>;
  observations: Maybe<Scalars['String']>;
  reason: Maybe<DisputeReason>;
  status: Maybe<DisputeStatus>;
};

export type DisputePayload = {
  __typename?: 'DisputePayload';
  disputeId: Maybe<Scalars['String']>;
  expiresAt: Maybe<Scalars['Datetime']>;
  uuid: Maybe<Scalars['String']>;
};

export enum DisputeReason {
  ItemNotAsDescribed = 'ITEM_NOT_AS_DESCRIBED',
  ItemNotReceived = 'ITEM_NOT_RECEIVED',
}

export enum DisputeStatus {
  Cancelled = 'CANCELLED',
  Escalated = 'ESCALATED',
  Opened = 'OPENED',
  ResolutionSubmitted = 'RESOLUTION_SUBMITTED',
  Resolved = 'RESOLVED',
}

export type Evidence = {
  __typename?: 'Evidence';
  disputeId: Maybe<Scalars['String']>;
  disputeState: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  uploader: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  from: Maybe<Scalars['String']>;
  rate: Maybe<Scalars['Decimal']>;
  to: Maybe<Scalars['String']>;
};

export type Features = {
  __typename?: 'Features';
  resolutionCenterDisputes: Maybe<Scalars['Boolean']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  name: Maybe<Scalars['String']>;
  price: Maybe<Amount>;
  quantity: Maybe<Scalars['Int']>;
  sku: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  contactEmail: Maybe<Scalars['String']>;
  organizationName: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  content: Maybe<Scalars['String']>;
  sender: Maybe<Scalars['String']>;
  sentAt: Maybe<Scalars['Datetime']>;
};

/** The order being managed */
export type Order = {
  __typename?: 'Order';
  activities: Maybe<Array<Maybe<Activity>>>;
  createdAt: Maybe<Scalars['Datetime']>;
  customer: Maybe<Customer>;
  dispute: Maybe<Dispute>;
  expiresAt: Maybe<Scalars['Datetime']>;
  items: Maybe<Array<Maybe<LineItem>>>;
  merchant: Maybe<Merchant>;
  /** @deprecated Use the merchant field instead */
  merchantUuid: Maybe<Scalars['String']>;
  payment: Maybe<Payment>;
  reference: Maybe<Scalars['String']>;
  status: Maybe<OrderStatus>;
  store: Maybe<Store>;
  total: Maybe<Amount>;
};

export type OrderMutations = {
  __typename?: 'OrderMutations';
  cancelDispute: Maybe<DisputePayload>;
  deleteDisputeEvidence: Maybe<DisputePayload>;
  escalateDispute: Maybe<DisputePayload>;
  openDispute: Maybe<DisputePayload>;
  payment: Maybe<PaymentMutation>;
  sendDisputeMessage: Maybe<DisputePayload>;
  signOut: Maybe<TokenPayload>;
  submitDisputeEvidence: Maybe<Evidence>;
  uploadRequest: Maybe<UploadRequest>;
};

export type OrderMutationsCancelDisputeArgs = {
  reason: InputMaybe<Scalars['String']>;
};

export type OrderMutationsDeleteDisputeEvidenceArgs = {
  uuid: Scalars['String'];
};

export type OrderMutationsEscalateDisputeArgs = {
  reason: Scalars['String'];
};

export type OrderMutationsOpenDisputeArgs = {
  observations: InputMaybe<Scalars['String']>;
  reason: DisputeReason;
};

export type OrderMutationsSendDisputeMessageArgs = {
  content: Scalars['String'];
};

export type OrderMutationsSubmitDisputeEvidenceArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type OrderMutationsUploadRequestArgs = {
  filename: Scalars['String'];
  mimeType: Scalars['String'];
};

export enum OrderStatus {
  InClaim = 'IN_CLAIM',
  InDispute = 'IN_DISPUTE',
  Paid = 'PAID',
  Pending = 'PENDING',
  RefundCompleted = 'REFUND_COMPLETED',
  RefundProcessing = 'REFUND_PROCESSING',
  RefundProposed = 'REFUND_PROPOSED',
}

export type Payment = {
  __typename?: 'Payment';
  paid: Maybe<Amount>;
  received: Maybe<Amount>;
  refund: Maybe<Refund>;
};

export type PaymentMutation = {
  __typename?: 'PaymentMutation';
  refund: Maybe<RefundMutation>;
};

export type Refund = {
  __typename?: 'Refund';
  acceptedAmount: Maybe<Amount>;
  acceptedRate: Maybe<ExchangeRate>;
  address: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  initialAmount: Maybe<Amount>;
  origin: Maybe<RefundOrigins>;
  proposal: Maybe<RefundProposal>;
  reason: Maybe<Scalars['String']>;
  sourceCryptoAmount: Maybe<Amount>;
  status: Maybe<RefundStatus>;
};

export type RefundMutation = {
  __typename?: 'RefundMutation';
  accept: Maybe<RefundPayload>;
  cancelProposal: Maybe<RefundProposalPayload>;
  createProposal: Maybe<RefundProposal>;
  reject: Maybe<RefundPayload>;
};

export type RefundMutationAcceptArgs = {
  address: Scalars['String'];
};

export type RefundMutationRejectArgs = {
  reason: Scalars['String'];
};

export enum RefundOrigins {
  Internal = 'INTERNAL',
  Payment = 'PAYMENT',
}

export type RefundPayload = {
  __typename?: 'RefundPayload';
  id: Maybe<Scalars['String']>;
};

export type RefundProposal = {
  __typename?: 'RefundProposal';
  convertedAmount: Maybe<Amount>;
  exchangeRate: Maybe<ExchangeRate>;
  expiresAt: Maybe<Scalars['Datetime']>;
};

export type RefundProposalPayload = {
  __typename?: 'RefundProposalPayload';
  refundId: Maybe<Scalars['String']>;
};

export enum RefundStatus {
  Accepted = 'ACCEPTED',
  Complete = 'COMPLETE',
  Processing = 'PROCESSING',
  Proposed = 'PROPOSED',
  Rejected = 'REJECTED',
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  order: Maybe<OrderMutations>;
  refund: Maybe<RefundMutation>;
  signIn: Maybe<TokenPayload>;
};

export type RootMutationTypeSignInArgs = {
  email: Scalars['String'];
  referenceCode: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  features: Maybe<Features>;
  /** The order being managed for which the token was issued */
  order: Maybe<Order>;
  refund: Maybe<Refund>;
};

export type Store = {
  __typename?: 'Store';
  name: Maybe<Scalars['String']>;
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  token: Maybe<Scalars['String']>;
};

export type UploadParams = {
  __typename?: 'UploadParams';
  acl: Maybe<Scalars['String']>;
  contentType: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  policy: Maybe<Scalars['String']>;
  successActionStatus: Maybe<Scalars['String']>;
  xAmzAlgorithm: Maybe<Scalars['String']>;
  xAmzCredential: Maybe<Scalars['String']>;
  xAmzDate: Maybe<Scalars['String']>;
  xAmzSignature: Maybe<Scalars['String']>;
};

export type UploadRequest = {
  __typename?: 'UploadRequest';
  params: Maybe<UploadParams>;
  uploadUrl: Maybe<Scalars['String']>;
};

export type ProposalFieldsFragment = {
  __typename?: 'RefundProposal';
  expiresAt: any | null;
  convertedAmount: {
    __typename?: 'Amount';
    value: any | null;
    currency: Currency | null;
    blockchain: string | null;
  } | null;
  exchangeRate: {
    __typename?: 'ExchangeRate';
    from: string | null;
    rate: any | null;
    to: string | null;
  } | null;
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  referenceCode: Scalars['String'];
}>;

export type SignInMutation = {
  __typename?: 'RootMutationType';
  signIn: { __typename?: 'TokenPayload'; token: string | null } | null;
};

export type AcceptRefundMutationVariables = Exact<{
  address: Scalars['String'];
}>;

export type AcceptRefundMutation = {
  __typename?: 'RootMutationType';
  refund: {
    __typename?: 'RefundMutation';
    accept: { __typename?: 'RefundPayload'; id: string | null } | null;
  } | null;
};

export type CreateProposalMutationVariables = Exact<{ [key: string]: never }>;

export type CreateProposalMutation = {
  __typename?: 'RootMutationType';
  refund: {
    __typename?: 'RefundMutation';
    createProposal: {
      __typename?: 'RefundProposal';
      expiresAt: any | null;
      convertedAmount: {
        __typename?: 'Amount';
        value: any | null;
        currency: Currency | null;
        blockchain: string | null;
      } | null;
      exchangeRate: {
        __typename?: 'ExchangeRate';
        from: string | null;
        rate: any | null;
        to: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetRefundStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetRefundStatusQuery = {
  __typename?: 'RootQueryType';
  refund: {
    __typename?: 'Refund';
    id: string | null;
    status: RefundStatus | null;
  } | null;
};

export type GetRefundProposalQueryVariables = Exact<{ [key: string]: never }>;

export type GetRefundProposalQuery = {
  __typename?: 'RootQueryType';
  refund: {
    __typename?: 'Refund';
    id: string | null;
    sourceCryptoAmount: {
      __typename?: 'Amount';
      currency: Currency | null;
      billPaymentCurrency: string | null;
    } | null;
    initialAmount: {
      __typename?: 'Amount';
      value: any | null;
      currency: Currency | null;
    } | null;
    proposal: {
      __typename?: 'RefundProposal';
      expiresAt: any | null;
      convertedAmount: {
        __typename?: 'Amount';
        value: any | null;
        currency: Currency | null;
        blockchain: string | null;
      } | null;
      exchangeRate: {
        __typename?: 'ExchangeRate';
        from: string | null;
        rate: any | null;
        to: string | null;
      } | null;
    } | null;
  } | null;
};

export const ProposalFieldsFragmentDoc = gql`
  fragment ProposalFields on RefundProposal {
    convertedAmount {
      value
      currency
      blockchain
    }
    exchangeRate {
      from
      rate
      to
    }
    expiresAt
  }
`;
export const SignInDocument = gql`
  mutation signIn($email: String!, $referenceCode: String!) {
    signIn(email: $email, referenceCode: $referenceCode) {
      token
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      referenceCode: // value for 'referenceCode'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options
  );
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const AcceptRefundDocument = gql`
  mutation acceptRefund($address: String!) {
    refund {
      accept(address: $address) {
        id
      }
    }
  }
`;
export type AcceptRefundMutationFn = Apollo.MutationFunction<
  AcceptRefundMutation,
  AcceptRefundMutationVariables
>;

/**
 * __useAcceptRefundMutation__
 *
 * To run a mutation, you first call `useAcceptRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRefundMutation, { data, loading, error }] = useAcceptRefundMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAcceptRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptRefundMutation,
    AcceptRefundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptRefundMutation,
    AcceptRefundMutationVariables
  >(AcceptRefundDocument, options);
}
export type AcceptRefundMutationHookResult = ReturnType<
  typeof useAcceptRefundMutation
>;
export type AcceptRefundMutationResult =
  Apollo.MutationResult<AcceptRefundMutation>;
export type AcceptRefundMutationOptions = Apollo.BaseMutationOptions<
  AcceptRefundMutation,
  AcceptRefundMutationVariables
>;
export const CreateProposalDocument = gql`
  mutation createProposal {
    refund {
      createProposal {
        ...ProposalFields
      }
    }
  }
  ${ProposalFieldsFragmentDoc}
`;
export type CreateProposalMutationFn = Apollo.MutationFunction<
  CreateProposalMutation,
  CreateProposalMutationVariables
>;

/**
 * __useCreateProposalMutation__
 *
 * To run a mutation, you first call `useCreateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalMutation, { data, loading, error }] = useCreateProposalMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProposalMutation,
    CreateProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProposalMutation,
    CreateProposalMutationVariables
  >(CreateProposalDocument, options);
}
export type CreateProposalMutationHookResult = ReturnType<
  typeof useCreateProposalMutation
>;
export type CreateProposalMutationResult =
  Apollo.MutationResult<CreateProposalMutation>;
export type CreateProposalMutationOptions = Apollo.BaseMutationOptions<
  CreateProposalMutation,
  CreateProposalMutationVariables
>;
export const GetRefundStatusDocument = gql`
  query getRefundStatus {
    refund {
      id
      status
    }
  }
`;

/**
 * __useGetRefundStatusQuery__
 *
 * To run a query within a React component, call `useGetRefundStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRefundStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRefundStatusQuery,
    GetRefundStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRefundStatusQuery, GetRefundStatusQueryVariables>(
    GetRefundStatusDocument,
    options
  );
}
export function useGetRefundStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRefundStatusQuery,
    GetRefundStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRefundStatusQuery,
    GetRefundStatusQueryVariables
  >(GetRefundStatusDocument, options);
}
export type GetRefundStatusQueryHookResult = ReturnType<
  typeof useGetRefundStatusQuery
>;
export type GetRefundStatusLazyQueryHookResult = ReturnType<
  typeof useGetRefundStatusLazyQuery
>;
export type GetRefundStatusQueryResult = Apollo.QueryResult<
  GetRefundStatusQuery,
  GetRefundStatusQueryVariables
>;
export const GetRefundProposalDocument = gql`
  query getRefundProposal {
    refund {
      id
      sourceCryptoAmount {
        currency
        billPaymentCurrency
      }
      initialAmount {
        value
        currency
      }
      proposal {
        ...ProposalFields
      }
    }
  }
  ${ProposalFieldsFragmentDoc}
`;

/**
 * __useGetRefundProposalQuery__
 *
 * To run a query within a React component, call `useGetRefundProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundProposalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRefundProposalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRefundProposalQuery,
    GetRefundProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRefundProposalQuery,
    GetRefundProposalQueryVariables
  >(GetRefundProposalDocument, options);
}
export function useGetRefundProposalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRefundProposalQuery,
    GetRefundProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRefundProposalQuery,
    GetRefundProposalQueryVariables
  >(GetRefundProposalDocument, options);
}
export type GetRefundProposalQueryHookResult = ReturnType<
  typeof useGetRefundProposalQuery
>;
export type GetRefundProposalLazyQueryHookResult = ReturnType<
  typeof useGetRefundProposalLazyQuery
>;
export type GetRefundProposalQueryResult = Apollo.QueryResult<
  GetRefundProposalQuery,
  GetRefundProposalQueryVariables
>;
