import cx from 'classnames';
import { PropsWithChildren } from 'react';
import Box, { BoxStyleProps } from '../../Box';

import styles from './DialogHeader.module.css';

interface DialogHeaderProps extends BoxStyleProps {
  className?: string;
  description?: string;
}

export default function DialogHeader({
  className,
  children,
  description,
  ...otherProps
}: PropsWithChildren<DialogHeaderProps>): JSX.Element {
  return (
    <Box className={cx(styles['base'], className)} {...otherProps}>
      <div className={styles['title']}>{children}</div>
      <div className={styles['description']}>{description}</div>
    </Box>
  );
}
