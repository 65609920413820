import { gql } from '@apollo/client';
import { ProposalFields } from './fragments';

export const GET_REFUND_STATUS = gql`
  query getRefundStatus {
    refund {
      id
      status
    }
  }
`;

export const GET_REFUND_PROPOSAL = gql`
  query getRefundProposal {
    refund {
      id
      sourceCryptoAmount {
        currency
        billPaymentCurrency
      }
      initialAmount {
        value
        currency
      }
      proposal {
        ...ProposalFields
      }
    }
  }
  ${ProposalFields}
`;
