import cx from 'classnames';
import { ComponentProps } from 'react';
import Box, { BoxStyleProps } from '../Box';
import Text from '../Text';
import { TextProps } from '../Text/Text';

import styles from './TextLink.module.css';

interface TextLinkProps
  extends Omit<ComponentProps<'a'>, 'color'>,
    BoxStyleProps,
    Pick<TextProps, 'size'> {
  className?: string;
  disabled?: boolean;
  'data-test-id'?: string;
}

export default function TextLink({
  className,
  disabled,
  size = 'inherit',
  'data-test-id': dataTestId,
  ...otherProps
}: TextLinkProps): JSX.Element {
  return (
    <Text
      as="a"
      className={cx(styles['base'], { 'is-disabled': disabled }, className)}
      size={size}
      color="none"
      {...otherProps}
    >
      {otherProps.children}
    </Text>
  );
}
