import { useEffect } from 'react';

interface UseScrollLockProps {
  container?: HTMLElement;
  disabled?: boolean;
}

export default function useScrollLock({
  container = document.body,
  disabled,
}: UseScrollLockProps) {
  useEffect(() => {
    if (disabled) {
      return;
    }

    const originalOverflow = container.style.overflow;

    container.style.overflow = 'hidden';

    return () => {
      container.style.overflow = originalOverflow;
    };
  }, [container, disabled]);
}
