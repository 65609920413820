import cx from 'classnames';
import { PropsWithChildren } from 'react';
import Box, { BoxStyleProps } from '../../Box';

import styles from './DialogActions.module.css';

interface DialogActionsProps extends BoxStyleProps {
  className?: string;
}

export default function DialogActions({
  className,
  children,
  ...otherProps
}: PropsWithChildren<DialogActionsProps>): JSX.Element {
  return <Box className={cx(styles['base'], className)} {...otherProps}>{children}</Box>;
}
