const chainNameBySymbol: Record<string, string> = {
  BTC: 'Bitcoin',
  'LN-BTC': 'Lightning',
  ETH: 'Ethereum',
  ESDT: 'Elrond',
  BEP20: 'Binance Smart Chain',
  DASH: 'Dash',
  ERD: 'MultiversX',
  POLYGON: 'Polygon',
  MATIC: 'Polygon',
  TRX: 'Tron',
  SUI: 'Sui',
};

export default chainNameBySymbol;
