import {
  autoUpdate,
  flip,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cx from 'classnames';
import { ReactElement, ReactNode, useState } from 'react';

import styles from './Tooltip.module.css';

import Box, { BoxStyleProps } from '../Box';
import Portal from '../Portal';

export type TooltipPlacement =
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'left-start'
  | 'left'
  | 'left-end'
  | 'right-start'
  | 'right'
  | 'right-end';

export interface TooltipProps extends BoxStyleProps {
  className?: string;
  wrapperClassName?: string;
  outerClassName?: string;
  openDelay?: number;
  closeDelay?: number;
  content?: ReactNode;
  children: ReactElement;
  placement?: TooltipPlacement;
  open?: boolean;
}

export default function Tooltip({
  className,
  outerClassName,
  wrapperClassName,
  openDelay = 300,
  closeDelay = 300,
  children,
  content,
  placement = 'bottom',
  open: controlledOpen,
  ...otherProps
}: TooltipProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const openValue = controlledOpen ?? open;

  const ARROW_SIZE = 6;
  const TOOLTIP_OFFSET = 4;
  const TOOLTIP_TOTAL_OFFSET = ARROW_SIZE + TOOLTIP_OFFSET;

  const {
    refs,
    floatingStyles,
    middlewareData,
    placement: updatedPlacement,
    context,
  } = useFloating({
    open: openValue,
    onOpenChange: setOpen,
    placement,
    middleware: [
      offset({
        mainAxis: TOOLTIP_TOTAL_OFFSET,
        alignmentAxis: -14,
      }),
      flip({
        fallbackPlacements: ['top', 'bottom', 'left', 'right'],
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    move: false,
    delay: {
      open: openDelay,
      close: closeDelay,
    },
  });

  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'tooltip',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <Box className={outerClassName} {...otherProps}>
      <div
        className={cx(styles['base'], wrapperClassName)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
        {openValue && (
          <Portal>
            <div
              ref={refs.setFloating}
              className={cx(
                [styles[`tooltip--placement-${updatedPlacement}`]],
                styles['tooltip'],
                className
              )}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div
                className={styles['arrow']}
                style={{
                  left: middlewareData.arrow?.x,
                  top: middlewareData.arrow?.y,
                }}
              />
              <div className={styles['content']}>{content}</div>
            </div>
          </Portal>
        )}
      </div>
    </Box>
  );
}
