export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: any;
  Decimal: any;
  Json: any;
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Maybe<Scalars['Datetime']>;
  data: Maybe<Scalars['Json']>;
  eventType: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  orderId: Maybe<Scalars['String']>;
};

export type Amount = {
  __typename?: 'Amount';
  billPaymentCurrency: Maybe<Scalars['String']>;
  blockchain: Maybe<Scalars['String']>;
  currency: Maybe<Currency>;
  value: Maybe<Scalars['Decimal']>;
};

export type Claim = {
  __typename?: 'Claim';
  escalationReason: Maybe<Scalars['String']>;
  winner: Maybe<ClaimWinner>;
};

export enum ClaimWinner {
  Buyer = 'BUYER',
  Merchant = 'MERCHANT',
}

export enum Currency {
  Aed = 'AED',
  Ars = 'ARS',
  Aud = 'AUD',
  Brl = 'BRL',
  Btc = 'BTC',
  Cad = 'CAD',
  Celo = 'CELO',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Czk = 'CZK',
  Dash = 'DASH',
  Dgb = 'DGB',
  Dkk = 'DKK',
  Dop = 'DOP',
  Egld = 'EGLD',
  Etc = 'ETC',
  Eth = 'ETH',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pol = 'POL',
  Ride = 'RIDE',
  Ron = 'RON',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Sui = 'SUI',
  Thb = 'THB',
  Trx = 'TRX',
  Twd = 'TWD',
  Usd = 'USD',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Utk = 'UTK',
  Zar = 'ZAR',
}

/** Information the buyer entered when creating the order */
export type Customer = {
  __typename?: 'Customer';
  address1: Maybe<Scalars['String']>;
  address2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

/** A dispute that needs to be resolved between buyer and merchant */
export type Dispute = {
  __typename?: 'Dispute';
  claim: Maybe<Claim>;
  escalationReason: Maybe<Scalars['String']>;
  evidence: Maybe<Array<Maybe<Evidence>>>;
  id: Maybe<Scalars['String']>;
  messages: Maybe<Array<Maybe<Message>>>;
  observations: Maybe<Scalars['String']>;
  reason: Maybe<DisputeReason>;
  status: Maybe<DisputeStatus>;
};

export type DisputePayload = {
  __typename?: 'DisputePayload';
  disputeId: Maybe<Scalars['String']>;
  expiresAt: Maybe<Scalars['Datetime']>;
  uuid: Maybe<Scalars['String']>;
};

export enum DisputeReason {
  ItemNotAsDescribed = 'ITEM_NOT_AS_DESCRIBED',
  ItemNotReceived = 'ITEM_NOT_RECEIVED',
}

export enum DisputeStatus {
  Cancelled = 'CANCELLED',
  Escalated = 'ESCALATED',
  Opened = 'OPENED',
  ResolutionSubmitted = 'RESOLUTION_SUBMITTED',
  Resolved = 'RESOLVED',
}

export type Evidence = {
  __typename?: 'Evidence';
  disputeId: Maybe<Scalars['String']>;
  disputeState: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  uploader: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  from: Maybe<Scalars['String']>;
  rate: Maybe<Scalars['Decimal']>;
  to: Maybe<Scalars['String']>;
};

export type Features = {
  __typename?: 'Features';
  resolutionCenterDisputes: Maybe<Scalars['Boolean']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  name: Maybe<Scalars['String']>;
  price: Maybe<Amount>;
  quantity: Maybe<Scalars['Int']>;
  sku: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  contactEmail: Maybe<Scalars['String']>;
  organizationName: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  content: Maybe<Scalars['String']>;
  sender: Maybe<Scalars['String']>;
  sentAt: Maybe<Scalars['Datetime']>;
};

/** The order being managed */
export type Order = {
  __typename?: 'Order';
  activities: Maybe<Array<Maybe<Activity>>>;
  createdAt: Maybe<Scalars['Datetime']>;
  customer: Maybe<Customer>;
  dispute: Maybe<Dispute>;
  expiresAt: Maybe<Scalars['Datetime']>;
  items: Maybe<Array<Maybe<LineItem>>>;
  merchant: Maybe<Merchant>;
  /** @deprecated Use the merchant field instead */
  merchantUuid: Maybe<Scalars['String']>;
  payment: Maybe<Payment>;
  reference: Maybe<Scalars['String']>;
  status: Maybe<OrderStatus>;
  store: Maybe<Store>;
  total: Maybe<Amount>;
};

export type OrderMutations = {
  __typename?: 'OrderMutations';
  cancelDispute: Maybe<DisputePayload>;
  deleteDisputeEvidence: Maybe<DisputePayload>;
  escalateDispute: Maybe<DisputePayload>;
  openDispute: Maybe<DisputePayload>;
  payment: Maybe<PaymentMutation>;
  sendDisputeMessage: Maybe<DisputePayload>;
  signOut: Maybe<TokenPayload>;
  submitDisputeEvidence: Maybe<Evidence>;
  uploadRequest: Maybe<UploadRequest>;
};

export type OrderMutationsCancelDisputeArgs = {
  reason: InputMaybe<Scalars['String']>;
};

export type OrderMutationsDeleteDisputeEvidenceArgs = {
  uuid: Scalars['String'];
};

export type OrderMutationsEscalateDisputeArgs = {
  reason: Scalars['String'];
};

export type OrderMutationsOpenDisputeArgs = {
  observations: InputMaybe<Scalars['String']>;
  reason: DisputeReason;
};

export type OrderMutationsSendDisputeMessageArgs = {
  content: Scalars['String'];
};

export type OrderMutationsSubmitDisputeEvidenceArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type OrderMutationsUploadRequestArgs = {
  filename: Scalars['String'];
  mimeType: Scalars['String'];
};

export enum OrderStatus {
  InClaim = 'IN_CLAIM',
  InDispute = 'IN_DISPUTE',
  Paid = 'PAID',
  Pending = 'PENDING',
  RefundCompleted = 'REFUND_COMPLETED',
  RefundProcessing = 'REFUND_PROCESSING',
  RefundProposed = 'REFUND_PROPOSED',
}

export type Payment = {
  __typename?: 'Payment';
  paid: Maybe<Amount>;
  received: Maybe<Amount>;
  refund: Maybe<Refund>;
};

export type PaymentMutation = {
  __typename?: 'PaymentMutation';
  refund: Maybe<RefundMutation>;
};

export type Refund = {
  __typename?: 'Refund';
  acceptedAmount: Maybe<Amount>;
  acceptedRate: Maybe<ExchangeRate>;
  address: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  initialAmount: Maybe<Amount>;
  origin: Maybe<RefundOrigins>;
  proposal: Maybe<RefundProposal>;
  reason: Maybe<Scalars['String']>;
  sourceCryptoAmount: Maybe<Amount>;
  status: Maybe<RefundStatus>;
};

export type RefundMutation = {
  __typename?: 'RefundMutation';
  accept: Maybe<RefundPayload>;
  cancelProposal: Maybe<RefundProposalPayload>;
  createProposal: Maybe<RefundProposal>;
  reject: Maybe<RefundPayload>;
};

export type RefundMutationAcceptArgs = {
  address: Scalars['String'];
};

export type RefundMutationRejectArgs = {
  reason: Scalars['String'];
};

export enum RefundOrigins {
  Internal = 'INTERNAL',
  Payment = 'PAYMENT',
}

export type RefundPayload = {
  __typename?: 'RefundPayload';
  id: Maybe<Scalars['String']>;
};

export type RefundProposal = {
  __typename?: 'RefundProposal';
  convertedAmount: Maybe<Amount>;
  exchangeRate: Maybe<ExchangeRate>;
  expiresAt: Maybe<Scalars['Datetime']>;
};

export type RefundProposalPayload = {
  __typename?: 'RefundProposalPayload';
  refundId: Maybe<Scalars['String']>;
};

export enum RefundStatus {
  Accepted = 'ACCEPTED',
  Complete = 'COMPLETE',
  Processing = 'PROCESSING',
  Proposed = 'PROPOSED',
  Rejected = 'REJECTED',
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  order: Maybe<OrderMutations>;
  refund: Maybe<RefundMutation>;
  signIn: Maybe<TokenPayload>;
};

export type RootMutationTypeSignInArgs = {
  email: Scalars['String'];
  referenceCode: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  features: Maybe<Features>;
  /** The order being managed for which the token was issued */
  order: Maybe<Order>;
  refund: Maybe<Refund>;
};

export type Store = {
  __typename?: 'Store';
  name: Maybe<Scalars['String']>;
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  token: Maybe<Scalars['String']>;
};

export type UploadParams = {
  __typename?: 'UploadParams';
  acl: Maybe<Scalars['String']>;
  contentType: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  policy: Maybe<Scalars['String']>;
  successActionStatus: Maybe<Scalars['String']>;
  xAmzAlgorithm: Maybe<Scalars['String']>;
  xAmzCredential: Maybe<Scalars['String']>;
  xAmzDate: Maybe<Scalars['String']>;
  xAmzSignature: Maybe<Scalars['String']>;
};

export type UploadRequest = {
  __typename?: 'UploadRequest';
  params: Maybe<UploadParams>;
  uploadUrl: Maybe<Scalars['String']>;
};
