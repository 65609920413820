import { ComponentProps } from 'react';
import Box, { BoxStyleProps } from '../Box';

type Color = 'royal' | 'full-royal' | 'full-white';

export interface LogoProps extends BoxStyleProps {
  className?: string;
  color?: Color;
  width?: ComponentProps<'svg'>['width'];
}

const colors: Record<Color, { text: string; logo: string }> = {
  royal: {
    text: '#000',
    logo: '#6932D4',
  },
  'full-royal': {
    text: '#6932D4',
    logo: '#6932D4',
  },
  'full-white': {
    text: '#FFF',
    logo: '#FFF',
  },
};

export default function Logo({
  className,
  color = 'full-white',
  width = 128,
  ...otherProps
}: LogoProps): JSX.Element {
  return (
    <Box
      className={className}
      as="svg"
      width={width}
      viewBox="0 0 91 21"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#xMoneyLogoA)">
        <path
          d="M82.6592 20.9999H80.3359L82.5107 15.873L76.916 3.052H79.4879L83.7089 13.0328L87.9299 3.052H90.2767L82.6563 20.9984L82.6592 20.9999Z"
          fill={colors[color].text}
        />
        <path
          d="M7.48476 4.92806L12.933 1.85786L12.0171 0L7.02681 2.1294C6.89833 2.18431 6.75593 2.18431 6.62746 2.1294L1.63861 0L0.722656 1.85786L6.17088 4.92956L0.722656 7.9998L1.63861 9.85762L6.62746 7.72822C6.75593 7.6733 6.89833 7.6733 7.02681 7.72822L12.0157 9.85762L12.9316 7.9998L7.48336 4.92806H7.48476Z"
          fill={colors[color].logo}
        />
        <path
          d="M24.574 12.689L29.0951 1.24805H32.4671V16.1658H30.2442V3.53179L25.2484 16.246H23.6497L18.7041 3.61046V16.1658H16.5566V1.24805H20.078L24.574 12.689Z"
          fill={colors[color].text}
        />
        <path
          d="M47.783 9.60981C47.783 13.5125 44.9611 16.4848 41.289 16.4848C37.6168 16.4848 34.7949 13.5125 34.7949 9.60981C34.7949 5.70712 37.5916 2.73486 41.289 2.73486C44.9863 2.73486 47.783 5.70712 47.783 9.60981ZM45.4359 9.60981C45.4359 6.98182 43.7631 4.96518 41.2904 4.96518C38.8176 4.96518 37.1448 7.03524 37.1448 9.60981C37.1448 12.1844 38.8176 14.2544 41.2904 14.2544C43.7631 14.2544 45.4359 12.2378 45.4359 9.60981Z"
          fill={colors[color].text}
        />
        <path
          d="M52.4333 16.0487H50.1602V2.93687H52.4333V5.35269C52.908 4.29166 54.3308 2.64453 56.629 2.64453C59.7509 2.64453 61.8983 5.11377 61.8983 8.51044V16.0487H59.6001V8.96154C59.6001 6.49231 58.2013 4.87335 56.0789 4.87335C53.9566 4.87335 52.4319 6.57247 52.4319 8.96154V16.0487H52.4333Z"
          fill={colors[color].text}
        />
        <path
          d="M76.5599 9.90187H66.2951C66.4191 12.3444 68.0682 14.2557 70.4904 14.2557C72.9132 14.2557 73.9617 12.8489 74.1629 12.4245H76.4108C76.2114 13.5656 74.6377 16.486 70.4921 16.486C66.6705 16.486 63.998 13.4603 63.998 9.61103C63.998 5.76176 66.6705 2.73608 70.267 2.73608C73.864 2.73608 76.5617 5.603 76.5617 9.90337L76.5599 9.90187ZM66.4442 7.88524H74.0371C73.4635 5.89384 72.1395 4.80614 70.2658 4.80614C68.2676 4.80614 66.9191 5.974 66.4442 7.88524Z"
          fill={colors[color].text}
        />
      </g>
      <defs>
        <clipPath id="xMoneyLogoA">
          <rect
            width="90"
            height="21"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </Box>
  );
}
