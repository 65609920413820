import { PropsWithChildren } from 'react';

import Box, { BoxStyleProps } from '../Box';
import Text from '../Text';

import styles from './InputWrapper.module.css';

export interface InputWrapperOwnProps {
  className?: string;
  error?: string;
}

type InputWrapperProps = InputWrapperOwnProps & BoxStyleProps;

export default function InputWrapper({
  className,
  children,
  error,
  ...boxProps
}: PropsWithChildren<InputWrapperProps>): JSX.Element {
  return (
    <Box className={className} {...boxProps}>
      <div>{children}</div>
      <Text
        className={styles['error']}
        size="tiny"
        weight="400"
        color="coral-500"
        as="div"
      >
        {error}
      </Text>
    </Box>
  );
}
