import cx from 'classnames';
import { motion, Variants } from 'framer-motion';
import {
  cloneElement,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';
import Box, { BoxStyleProps } from '../Box';
import useResponsive, { SCREEN_SIZE } from '../useResponsive';

import styles from './PhoneMockup.module.css';
import PhoneMockupAnimation from '../PhoneMockupAnimation/PhoneMockupAnimation';
import { usePhoneMockupLayoutContext } from '../PhoneMockupLayout';

export interface PhoneMockupProps extends BoxStyleProps {
  className?: string;
  bodyClassName?: string;
  header?: ReactNode;
  bottomSheet?: ReactNode;
  onAnimationComplete?: () => void;
}

const PhoneMockup = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PhoneMockupProps>
>(
  (
    {
      className,
      bodyClassName,
      header,
      bottomSheet,
      children,
      onAnimationComplete,
      ...otherProps
    },
    ref
  ) => {
    const bp = useResponsive();
    const isMobile = bp === SCREEN_SIZE.XS || bp === SCREEN_SIZE.SM;

    const { animationDirection, setAnimationDirection } =
      usePhoneMockupLayoutContext();

    const variants: Variants = {
      initial: () => {
        return {
          y: '120px',
          opacity: 0,
        };
      },
      animate: () => {
        return {
          y: 0,
          opacity: 1,
          transition: {
            delay: 0.1,
            ease: 'easeOut',
            duration: 0.3,
          },
        };
      },
    };

    return (
      <Box
        as={motion.div}
        {...otherProps}
        style={{ width: '100%' }}
        variants={variants}
        initial={animationDirection === 'initial' ? 'initial' : 'animate'}
        animate="animate"
      >
        <div ref={ref} className={cx(styles['base'], className)}>
          <div
            style={{ ...(!!bottomSheet && { overflow: 'hidden' }) }}
            className={styles['scrollable-area']}
          >
            {header}
            <PhoneMockupAnimation
              className={styles['phone-mockup-wrapper']}
              onAnimationComplete={() => {
                if (animationDirection === 'initial') {
                  setAnimationDirection('forwards');
                }

                onAnimationComplete && onAnimationComplete();
              }}
            >
              <div className={cx(styles['body'], bodyClassName)}>
                {children}
              </div>
            </PhoneMockupAnimation>
          </div>
          {bottomSheet &&
            cloneElement(bottomSheet as ReactElement, {
              ...(isMobile ? { container: document.body } : {}),
            })}
        </div>
      </Box>
    );
  }
);

export default PhoneMockup;
