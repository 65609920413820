import cx from 'classnames';
import { KeyboardEvent, PropsWithChildren, useEffect, useRef } from 'react';
import Box, { BoxStyleProps } from '../Box';
import Portal from '../Portal';

import useScrollLock from '../useScrollLock';

import styles from './Modal.module.css';

// TODO: Focus-trap behaviour should be added later on

export interface ModalProps extends BoxStyleProps {
  className?: string;
  contentClassName?: string;
  'aria-describedby'?: string;
  'aria-labelledby'?: string;
  disableCloseOnBackdropClick?: boolean;
  disableCloseOnEscapePress?: boolean;
  container?: HTMLElement;
  onRequestClose?: () => void;
  disableScrollLock?: boolean;
}

export default function Modal({
  className,
  contentClassName,
  children,
  'aria-describedby': ariaDescribedby,
  'aria-labelledby': ariaLabelledby,
  disableCloseOnBackdropClick,
  disableCloseOnEscapePress,
  container,
  onRequestClose,
  disableScrollLock,
  ...otherProps
}: PropsWithChildren<ModalProps>) {
  const ref = useRef<HTMLDivElement>(null);

  useScrollLock({
    container,
    disabled: disableScrollLock,
  });

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (
      event.key === 'Escape' &&
      !disableCloseOnEscapePress &&
      onRequestClose
    ) {
      // Swallow the event, in case someone is listening for the escape key on the body.
      event.stopPropagation();

      onRequestClose();
    }
  }

  function handleOverlayClick() {
    if (!disableCloseOnBackdropClick && onRequestClose) {
      onRequestClose();
    }
  }

  return (
    <Portal container={container}>
      <Box className={cx(styles['base'], className)} {...otherProps}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <div
          role="dialog"
          aria-modal="true"
          ref={ref}
          aria-describedby={ariaDescribedby}
          aria-labelledby={ariaLabelledby}
          className={cx(styles['content'], contentClassName)}
          tabIndex={-1}
          onKeyDown={handleKeyDown}
        >
          {children}
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          aria-hidden
          className={styles['overlay']}
          onClick={handleOverlayClick}
        />
      </Box>
    </Portal>
  );
}
